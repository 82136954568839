<template>
  <div class="mt-1 mx-3">
    <create-mandate-codes></create-mandate-codes>
  </div>
</template>

<script>
import CreateMandateCodes from "@/components/profile/companies-products/mandate-codes/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CreateMandateCodes,
  },
  created() {
    this.notifyQueue({
      text: "Mandati",
      path: "module.PROF",
      tab: "MandateCodes",
      level: 3,
    });
    this.notifyQueue({
      text: "Inserisci Mandato",
      path: "mandate_codes.create",
      level: 4,
    });
  },
};
</script>
