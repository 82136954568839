<template>
  <div class="mt-1 mx-3">
    <mandate-codes> </mandate-codes>
  </div>
</template>

<script>
import MandateCodes from "@/components/profile/companies-products/mandate-codes/index.vue";
export default {
  props: {
    msg: String,
  },
  components: {
    MandateCodes,
  },
  created() {},
};
</script>
